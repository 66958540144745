import './App.css';
import Landing from './Views/Landing';

function App() {
  return (
    <div>
     <Landing />
    </div>
  );
}

export default App;
